import React from 'react';
import './Heading.scss';

const Heading = () => {
  return (
    <div
      id="pricing-heading"
      className="px-3 pb-3 pb-5 mt-3 pt-md-2 pb-md-2 mx-auto text-center"
    >
      <h4>Pricing</h4>
      <p>We offer personalized plans to meet your specific requirements.</p>
    </div>
  );
};

export default Heading;
