import React from 'react';
import './Footer.scss';

const Footer = () => {
  // Array of sections with their respective links
  const sections = [
    {
      title: 'Product',
      links: [
        { text: 'Why barbcut?', href: '#' },
        { text: 'Success stories', href: '#' },
        { text: 'Pricing', href: '#' },
      ],
    },
    {
      title: 'Company',
      links: [
        { text: 'About us', href: '#' },
        { text: 'Careers', href: '#' },
        { text: 'Press', href: '#' },
      ],
    },
    {
      title: 'Resources',
      links: [
        { text: 'Partners', href: '#' },
        { text: 'Events', href: '#' },
      ],
    },
    {
      title: 'Community',
      links: [
        { text: 'Blogs', href: '#' },
        { text: 'Forums', href: '#' },
      ],
    },
    {
      title: 'Support',
      links: [
        { text: 'Help center', href: '#' },
        { text: 'Guides', href: '#' },
        { text: 'FAQ', href: '#' },
      ],
    },
  ];

  return (
    <footer className="pt-4 my-md-5 pt-md-5 border-top">
      <div className="row">
        <div className="col-6 col-md">
          <img
            className="mb-2"
            src="./barbcut-symbol-32x32.png"
            alt="barbcut"
            width="24"
            height="24"
          />
          <small className="d-block mb-3 text-muted">&copy; 2022-23</small>
        </div>
        {sections.map((section, index) => (
          <div className="col-6 col-md" key={index}>
            <h6>{section.title}</h6>
            <ul className="list-unstyled text-small">
              {section.links.map((link, linkIndex) => (
                <li key={linkIndex}>
                  <a className="text-muted" href={link.href}>
                    {link.text}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </footer>
  );
};

export default Footer;
