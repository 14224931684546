import React from 'react';
import './Header.scss';
import { useLocation, Link } from 'react-router-dom';

const Header = () => {
  const navLinks = getNavLinks(useLocation());

  return (
    <header
      id="bg-header"
      className="navbar navbar-expand flex-column flex-md-row bd-navbar border-bottom shadow-sm sticky-top"
    >
      <a className="navbar-brand mr-0 mr-md-2" href="/">
        <img src="./barbcut-logo.svg" alt="barbcut" width="180" height="40" />
      </a>
      <div className="collapse navbar-collapse justify-content-end">
        <ul className="navbar-nav bd-navbar-nav flex-row justify-content-end">
          {navLinks.map((link, index) => (
            <li
              className={'nav-item ' + (link.active ? 'active' : '')}
              key={index}
            >
              <Link to={link.href} className="nav-link">
                {link.text}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </header>
  );
};

export default Header;
function getNavLinks(location) {
  const homePage = '/home';
  let onHomePage = false;
  const pricingPage = '/pricing';
  let onPricingPage = false;
  const contactUsPage = '/contactus';
  let onContactUsPage = false;

  switch (location.pathname) {
    case pricingPage:
      onPricingPage = true;
      break;
    case contactUsPage:
      onContactUsPage = true;
      break;
    default:
      onHomePage = true;
      break;
  }

  // Array of navigation links
  const navLinks = [
    { text: 'Home', href: homePage, active: onHomePage },
    { text: 'Pricing', href: pricingPage, active: onPricingPage },
    { text: 'Contact Us', href: contactUsPage, active: onContactUsPage },
  ];
  return navLinks;
}
