import React from 'react';
import './Item.scss';
import { Link } from 'react-router-dom';

const Item = ({
  title,
  price,
  subTitle,
  features,
  registerText,
  registerLink,
}) => {
  return (
    <>
      <div className="card-header">
        <h4 className="my-0">{title}</h4>
      </div>
      <div className="card-body">
        <h4 className="card-title border-bottom pb-3">
          ₹ {price}
          <small className="text-muted">/ mo</small>
        </h4>
        <ul className="list-unstyled mt-3 mb-4">
          <li className="text-left mb-2" key="-1">
            <strong>{subTitle}</strong>
          </li>
          {features.map((feature, index) => (
            <li className="text-left" key={index}>
              <div className="row">
                <div className="col-auto pr-1 pl-1">
                  <img
                    src="./check-circle-16.svg"
                    height="16"
                    width="16"
                    alt="-"
                  />
                </div>
                <div className="col pr-1 pl-1">
                  <p className="mb-1">{feature.description}</p>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
      <div className="card-footer bg-transparent">
        <Link
          to={registerLink.pathname}
          state={{ plan: registerLink.state.plan }}
        >
          <button
            type="submit"
            className="btn btn-lg btn-block pricing-card-footer-button"
          >
            {registerText}
          </button>
        </Link>
      </div>
    </>
  );
};

export default Item;
