import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import './Index.scss';

const Contact = () => {
  // State to hold form data
  const [isSubmitted, setIsSubmitted] = useState(false);
  // const [isError, setIsError] = useState(false);
  // const [errorData, setErrorData] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [salonName, setSalonName] = useState('');
  const [salonLocation, setSalonLocation] = useState('');
  const location = useLocation();
  const { plan } = location.state || { plan: 'all' };

  // Function to handle form submit
  const handleSubmit = event => {
    event.preventDefault();
    const formData = { name, email, salonName, salonLocation, plan };

    fetch('/api/contactus', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    })
      .then(response => response.json())
      .then(data => {
        setIsSubmitted(true); // Show success message
        // setIsError(false);
        console.log('Success:', data);
      })
      .catch(error => {
        setIsSubmitted(true); // Show error message
        // setIsError(true);
        // setErrorData('Please try again later');
        console.error('Error:', error);
      });
  };

  // Update state on input change
  const handleNameChange = event => {
    setName(event.target.value);
  };
  const handleEmailChange = event => {
    setEmail(event.target.value);
  };
  const handleSalonNameChange = event => {
    setSalonName(event.target.value);
  };
  const handleSalonLocationChange = event => {
    setSalonLocation(event.target.value);
  };

  return (
    <>
      <div id="contactus-container" className="mx-auto text-center">
        <div className="container mx-auto mt-4">
          <h4>
            {plan === 'all' ? (
              <span>Contact Us</span>
            ) : (
              <span>I'm Interested</span>
            )}
          </h4>
          {isSubmitted && (
            <p id="form-submitted">
              Thank you for contacting us. We will get back to you shortly.
            </p>
          )}

          {!isSubmitted && (
            <form onSubmit={handleSubmit} className="mx-auto">
              <div className="row py-1">
                <label htmlFor="name">Name</label>
                <input
                  type="text"
                  className="form-control form-control-sm"
                  id="name"
                  placeholder="Enter your name"
                  autoComplete="given-name"
                  onChange={handleNameChange}
                  required
                />
              </div>

              <div className="row py-1">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  className="form-control form-control-sm"
                  id="email"
                  placeholder="Enter your email"
                  autoComplete="email"
                  onChange={handleEmailChange}
                  required
                />
              </div>

              <div className="row py-1">
                <label htmlFor="salon-name">Salon Name</label>
                <input
                  type="text"
                  className="form-control form-control-sm"
                  id="salon-name"
                  placeholder="Enter your salon's name"
                  autoComplete="Salon name"
                  onChange={handleSalonNameChange}
                />
              </div>

              <div className="row py-1">
                <label htmlFor="salon-location">Salon Address</label>
                <input
                  type="text"
                  className="form-control form-control-sm"
                  id="salon-location"
                  placeholder="Enter your salon's address"
                  autoComplete="Salon location"
                  onChange={handleSalonLocationChange}
                />
              </div>

              {plan !== 'all' && (
                <div className="row py-1">
                  <label htmlFor="plan">Plan</label>

                  <input
                    type="text"
                    className="form-control form-control-sm"
                    id="plan"
                    value={plan}
                    placeholder="Plan"
                    autoComplete="off"
                    disabled
                  />
                </div>
              )}

              {plan === 'all' && <input type="hidden" id="plan" value={plan} />}
              <input
                type="text"
                id="phone"
                name="phone"
                autoComplete="phone"
                defaultValue=""
              />
              <div className="row my-4">
                <div className="mx-auto">
                  <button
                    type="submit"
                    className="btn contactus-submit-button px-5"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </form>
          )}
        </div>
      </div>
    </>
  );
};

export default Contact;
