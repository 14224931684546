import React from 'react';

const NoPage = () => {
  return (
    <>
      <h1>Page not found!</h1>
    </>
  );
};

export default NoPage;
