import React from 'react';
import './Index.scss';

const Home = () => {
  const list = [
    {
      title: 'Customer',
      titleCss: 'slide-in-customer',
      icon: './icons/customer-icon-500.png',
      features: [
        'Can book appointments using our app',
        'Can choose the barber of their choice',
        'Can set preferred days and time',
        'Can easily find out when the barbers are free',
        'Can easily find your salon location',
        'Can store their favourite haircut',
        'Can see opening and closing times',
        'and much more ...',
      ],
      image: './landing-page/mobile-500.jpg',
      imageSize: '250px',
      imageCss: 'img-fluid',
    },
    {
      title: 'Staff',
      titleCss: 'slide-in-staff',
      icon: './icons/barber-icon-500.jpg',
      features: [
        'Can have a managed workload',
        'Can see their rostered days',
        'Can view next in line customers',
        'Can see customers usual hair-style cut',
        'Can set their preferred lunch break times',
        'Can request days or time off',
        'and much more ...',
      ],
      image: './landing-page/barber-500.jpg',
      imageSize: '220px',
      imageCss: 'img-fluid ml-2 my-4 bg-body rounded-circle staff-image',
    },
    {
      title: 'Hair Salon Owner',
      titleCss: 'slide-in-owner',
      icon: './icons/owner-icon-500.jpg',
      features: [
        'Can view daily staff schedule',
        'Can create staff roster',
        'Can see staff performance',
        'Can see monthly/yearly earnings',
        'Can maintain store inventory',
        'Can run promotions during quiet times',
        'Can charge surcharge during peak times',
        'and much more ...',
      ],
      image: './landing-page/shop-500.jpg',
      imageSize: '250px',
      imageCss: 'img-fluid mt-4',
    },
  ];

  return (
    <>
      <div id="home-container">
        <div className="px-3 py-3 pt-md-2 pb-md-2 mx-auto text-center">
          <h2 className="headline my-4 slide-in-heading">
            <span className="start">Mobile and online solutions </span>
            <span className="end">for hair salons</span>
          </h2>
          {list.map(item => (
            <div className={'row ' + item.titleCss} key={item.title}>
              <div className="col-md-8">
                <div className="container px-0">
                  <div className="row">
                    <div className="col-auto pl-2 pt-2 mt-1">
                      <img
                        className="align-self-start mb-1 bg-body rounded-circle shadow"
                        src={item.icon}
                        alt=""
                        width="60"
                        height="60"
                      />
                    </div>
                    <div className="col px-2 mt-3 pt-2 align-items-left">
                      <h4 className="mx-0 pl-0 text-left feature-title">
                        {item.title}
                      </h4>
                      <ul className="text-left pl-3 mx-0">
                        {item.features.map(feature => (
                          <li key={feature}>{feature}</li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mx-auto">
                <img
                  src={item.image}
                  className={item.imageCss}
                  alt=""
                  height={item.imageSize}
                  width={item.imageSize}
                  style={{
                    maxWidth: item.imageSize,
                    maxHeight: item.imageSize,
                  }}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Home;
