import React from 'react';
import List from './List';
import Heading from './Heading';

const Pricing = () => {
  return (
    <>
      <Heading />
      <List />
    </>
  );
};

export default Pricing;
