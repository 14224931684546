import React from 'react';
import Item from './Item';
import './List.scss';

const List = () => {
  // Array of pricing plans with their respective features
  const plans = [
    {
      title: 'Basic',
      price: 500,
      subTitle: "What's included?",
      registerText: "I'm Interested",
      registerLink: { pathname: '/contactus', state: { plan: 'Basic' } },
      features: [
        {
          description:
            'Clients can book appointments directly through our app.',
        },
        {
          description:
            'Salon owners can easily update and manage salon availability.',
        },
        { description: 'Access to instructional videos for guidance.' },
        { description: 'Access to help center via email.' },
      ],
    },
    {
      title: 'Professional',
      price: 1000,
      subTitle: 'Everything in Basic, plus:',
      registerText: "I'm Interested",
      registerLink: { pathname: '/contactus', state: { plan: 'Professional' } },
      features: [
        { description: 'Allow clients to choose their preferred barber.' },
        { description: 'Create a staff roster.' },
        { description: 'Allocate time slots for VIP customers.' },
        { description: 'Apply a surcharge automatically during peak seasons.' },
      ],
    },
    {
      title: 'Business',
      price: 1500,
      subTitle: 'Everything in Pro, plus:',
      registerText: "I'm Interested",
      registerLink: { pathname: '/contactus', state: { plan: 'Business' } },
      features: [
        { description: 'Manage inventory.' },
        { description: 'Generate staff performance reports.' },
        { description: "Generate owners' earnings reports." },
        { description: 'Priority support.' },
      ],
    },
  ];

  return (
    <div id="pricing-list" className="card-deck mb-3 text-center">
      {plans.map((plan, index) => (
        <div className="card mb-4 shadow-sm" key={index}>
          <Item
            title={plan.title}
            price={plan.price}
            subTitle={plan.subTitle}
            features={plan.features}
            registerText={plan.registerText}
            registerLink={plan.registerLink}
          />
        </div>
      ))}
    </div>
  );
};

export default List;
